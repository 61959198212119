import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCarSide } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Fopa Lisconus Car Rental
			</title>
			<meta name={"description"} content={"Welcome to Fopa Lisconus Car Rental, which we blend reliability, selection and extraordinary service to offer you a unique car rental experience."} />
			<meta property={"og:title"} content={"Fopa Lisconus Car Rental"} />
			<meta property={"og:description"} content={"Welcome to Fopa Lisconus Car Rental, which we blend reliability, selection and extraordinary service to offer you a unique car rental experience."} />
			<meta property={"og:image"} content={"https://fopalisconus.com/img/0.jpg"} />
			<link rel={"shortcut icon"} href={"https://fopalisconus.com/img/2389246.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://fopalisconus.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://fopalisconus.com/img/2389246.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://fopalisconus.com/img/2389246.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://fopalisconus.com/img/2389246.png"} />
			<meta name={"msapplication-TileImage"} content={"https://fopalisconus.com/img/2389246.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						Fopa Lisconus Car Rental
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
					Welcome to Fopa Lisconus Car Rental, where we blend reliability, choice, and exceptional service to provide you with an unparalleled car rental experience. Whether you're traveling for business, heading on a road trip, or just need a temporary ride, we have the perfect vehicle for you.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-primary"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="--primary"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							href="/contacts"
							type="link"
							text-decoration-line="initial"
						>
							Contact us
						</Button>
					</Box>
				</Box>
				<Image
					src="https://fopalisconus.com/img/0.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Text as="h2" font="--headline2" margin="0 0 24px 0">
			Why Choose Fopa Lisconus?
			</Text>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Diverse Fleet
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						From economy cars for budget travelers to luxury models for a more upscale experience, our diverse fleet caters to all preferences and needs.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Competitive Rates 
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Enjoy transparent pricing and competitive rates, ensuring you get the best value for your rental.
						</Text>
					</Box>
				</Box>
				<Box width="33.333%" padding="16px 16px 16px 16px" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h3" font="--headline3" margin="12px 0">
						Uncompromised Quality
						</Text>
						<Text as="p" font="--base" margin="12px 0" color="--greyD2">
						Each car in our fleet is meticulously maintained and serviced to ensure a safe and comfortable journey.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://fopalisconus.com/img/1.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Unique Advantages
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Hassle-Free Booking Process – Our straightforward booking process makes reserving your car quick and easy.
Personalized Customer Service – Our dedicated team is here to assist you, ensuring a smooth rental experience from start to finish.
				</Text>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
				>
					<Image
						left={0}
						min-height="100%"
						top={0}
						right={0}
						bottom={0}
						src="https://fopalisconus.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://fopalisconus.com/img/3.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						position="absolute"
						display="block"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						src="https://fopalisconus.com/img/4.jpg"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="25%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					lg-text-align="center"
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Fopa Lisconus Car Rental - where every driving is an adventure
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});